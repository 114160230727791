

<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="tgju-widgets-block col-md-12 profile-history">
            <div class="tgju-widget light has-icon">

                <div v-if="cryptoembed == null" class="tgju-widget-title dot m-0">
                    <h2>تاریخچه {{ market_history.info.title }}</h2>
                    <span class="tgju-widget-up-i">{{ market_history.info.time }} <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                </div>

                <div class="clearfix tgju-widget-forms">
                    <div class="filters-wrapper">
                        <div class="filter-wrapper">
                            <div class="search-box" data-target="profile-tour-step-11">
                                <div class="date-icon"></div>
                                <input type="text" class="search" id="datatables-search-box" placeholder="جستجو ..." value="" name="search">
                            </div>
                        </div>
                        <div class="filter-wrapper date-box" data-target="profile-tour-step-12">
                            <div class="date-icon"></div>
                                <date-picker
                                    id="history-from"
                                    v-model="fromDate"
                                    placeholder="از تاریخ"
                                    appendTo="body"
                                    class="chartFromDate"
                                    inputClass="Datepicker"
                                    :clearable="true"
                                    :autoSubmit="true"
                                    @change="chartDatesChanged()"
                                ></date-picker>
                            <!-- <input type="text" autocomplete="off" value="" placeholder="از تاریخ" id="history-from"> -->
                        </div>
                        <div class="filter-wrapper date-box">
                            <div class="date-icon"></div>
                                <date-picker
                                    id="history-to"
                                    v-model="toDate"
                                    placeholder="تا تاریخ"
                                    appendTo="body"
                                    class="chartEndDate"
                                    inputClass="Datepicker"
                                    :clearable="true"
                                    :autoSubmit="true"
                                    @change="chartDatesChanged()"
                                ></date-picker>
                            <!-- <input type="text" autocomplete="off" value="" placeholder="تا تاریخ" id="history-to"> -->
                        </div>
                    </div>

                    <div v-if="cryptoembed == null" class="tv-select-btn" style="float: left;">
                        <button class="tv-select" data-target="profile-tour-step-13" href="#" onclick="tv_select('archive'); return false;"><i class="fa fa-th" style="font-size: 14px;font-weight: 100;position: relative;top: 2px;margin-left: 3px;"></i> انتخاب شاخص</button>
                    </div>
                    <!-- {% if cryptoembed != null %}
                        <div class="filters-wrapper float-left padding-right-0-crypto">
                            <div class="filter-wrapper">
                                <div class="labeled"  data-target="tab-tour-select-coin">
                                    <div class="labeled-name">
                                        <span><i class="fa fa-usd" aria-hidden="true"></i>انتخاب ارز</span>
                                    </div>
                                    <div class="labeled-data">
                                        <select class="select-market-crypto">
                                            {{ crypto_coins_selector|raw }}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {% endif %} -->
                </div>

                <div id="stocks-table-data" class="tables-data table-mobile-grid" style="padding-bottom: 20px;">
                    <table id="datatables-container-1" class="table widgets-dataTable table-hover text-center history-table">
                        <thead class="text-center">
                            <tr>
                                <th data-target="profile-tour-step-14">بازگشایی<span class="question-mark" title="<h4><span class='blt'></span>بازگشایی</h4><span class='tooltip-desc'>به معنای نخستین نرخی است که برای شاخص مورد نظر در روز جاری به ثبت رسید</span>">؟</span></th>
                                <th data-target="profile-tour-step-15">کمترین<span class="question-mark" title="<h4><span class='blt'></span>کمترین</h4><span class='tooltip-desc'>پایین ترین مقدار ثبت شده برای شاخص در طول روز جاری</span>">؟</span></th>
                                <th data-target="profile-tour-step-16">بیشترین<span class="question-mark" title="<h4><span class='blt'></span>بیشترین</h4><span class='tooltip-desc'>بالاترین مقدار ثبت شده برای شاخص در طول روز جاری</span>">؟</span></th>
                                <th data-target="profile-tour-step-17">پایانی<span class="question-mark" title="<h4><span class='blt'></span>پایانی</h4><span class='tooltip-desc'>به معنای آخرین قیمتی است که برای شاخص مورد نظر در روز جاری به ثبت رسید</span>">؟</span></th>
                                <th data-target="profile-tour-step-18">میزان تغییر</th>
                                <th data-target="profile-tour-step-19">درصد تغییر</th>
                                <th data-target="profile-tour-step-20">تاریخ / میلادی</th>
                                <th data-target="profile-tour-step-21">تاریخ / شمسی</th>
                            </tr>
                        </thead>
                        <tbody id="table-list">
                            <tr v-for="(item, index) in market_history.summary_table" :key="index">
                                <td>{{ item.open }}</td>
                                <td>{{ item.min }}</td>
                                <td>{{ item.max }}</td>
                                <td>{{ item.price }}</td>
                                <td><span :class="`${ item.dt }`" dir="ltr">{{ item.d }}</span></td>
                                <td v-if="item.dp != '-'"><span :class="`${ item.dt }`" dir="ltr" v-html="item.dp + '%'"></span><td v-else>-</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.jalali_date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </slot>
</div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    name: "MarketHistoryComponent",
    data() {
        return {
            widgetLoad: true,
            initDatatable: false,
            market_history: [],
            cryptoembed: null,
            fromDate: null,
            toDate: null,
            datatable: null,
        }
    },
    updated() {
        if (!this.initDatatable) {
            this.initDatatables();
            this.initDatatable = true;
        }
    },
    mounted() {
        this.getProfileHistory();
    },
    methods: {
        getProfileHistory() {
            this.$helpers
                .makeRequest("GET", `/market/profile-history/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_history = api_response.data.response.detail.response;
                        this.$parent.title = this.market_history.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                        this.initDatatables();
                    }
                });
        },
        initDatatables() {
            this.datatable = $('#datatables-container-1').DataTable({
                        language: {
                            "sProcessing": "درحال پردازش...",
                            "sLengthMenu": "نمایش محتویات : _MENU_",
                            "sZeroRecords": "موردی یافت نشد",
                            "sInfo": "نمایش _START_ تا _END_ از مجموع _TOTAL_ مورد",
                            "sInfoEmpty": "خالی",
                            "sInfoFiltered": "(فیلتر شده از مجموع _MAX_ مورد)",
                            "sInfoPostFix": "",
                            "sSearch": "فیلتر : ",
                            "sUrl": "",
                            "oPaginate": {
                                "sFirst": "ابتدا",
                                "sPrevious": "قبلی",
                                "sNext": "بعدی",
                                "sLast": "انتها"
                            }
                        },
                        aaSorting: [],
                        ordering: true,
                        paging: true,
                        info: true,
                        columnDefs: [{
                            "targets": 'no-sort',
                            "orderable": false,
                        }],
                        pageLength: 30,
                        // TODO: "<'row'<'col-md-9'l T C><'col-md-3'f>r>t<'row'<'col-md-12'p i>>R",
                        sDom: "<''<'col-md-9'l T C><'col-md-3'f>r>t<''<'col-md-12'p i>>R",
                        direction: 'rtl',
                        processing: true,
                        serverSide: true,
                        ajax: {
                                "url": `https://api.tgju.org/v1/market/indicator/summary-table-data/${this.$route.params.name}?lang=fa&identification=smart&order_dir=asc`,
                                "dataType": "json",
                                "data": this.history_datatables_params
                            }
                    });

            var test_ajax = this.datatable;
            $('#datatables-search-box').keyup(function() {
                test_ajax.search($(this).val()).draw();
            });
        },
        chartDatesChanged() {
            // let start_timestamp = ( parin_lang == 'fa' ) ? new JDate(this.fromDate).getTime() : new Date(this.fromDate).getTime();
            // let start_timestamp = new JDate(this.fromDate).getTime();
            // let end_timestamp = ( parin_lang == 'fa' ) ? new JDate(this.toDate).getTime() : new Date(this.toDate).getTime();
            // let end_timestamp = new JDate(this.toDate).getTime();
            this.datatable.ajax.reload();
        },
        history_datatables_params(data) {
            // TODO: به vpd پایین دقت شود شاید داینامیک عوض بشه
            var from = $('#vpd-history-from').val();
            var to = $('#vpd-history-to').val();

            var order_col = data.order.length ? data.order[0]['column'] : null;

            if ( order_col !== null ) {
                switch ( order_col ) {
                    case 0:
                        order_col = 'open';
                        break;
                    case 1:
                        order_col = 'min';
                        break;
                    case 2:
                        order_col = 'max';
                        break;
                    case 3:
                        order_col = 'price';
                        break;
                    case 4:
                        order_col = 'd';
                        break;
                    case 5:
                        order_col = 'dp';
                        break;
                    case 6:
                        order_col = 'timestamp';
                        break;
                    case 7:
                        order_col = 'timestamp';
                        break;
                }
            }

            return $.extend({}, data, {
                search: $('.filters-wrapper input.search').val().trim(),
                order_col: order_col,
                order_dir: data.order.length ? data.order[0]['dir'] : null,
                from: from,
                to: to,
                convert_to_ad: 1,
            });
        },
    },
}
</script>